import React, { useCallback, useEffect, useState } from "react";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import { differenceWith, isEqual } from "lodash";
import styled from "styled-components";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import InnerContainer from "~/components/Containers/InnerContainer";
import OutterContainer from "~/components/Containers/OutterContainer";
import LoadingError from "~/components/Loaders/LoadingError";
import AddTimeblock from "~/components/Timeblocks/AddTimeBlock";
import DisplayTimeBlock from "~/components/Timeblocks/DisplayTimeBlock";
import nurseService from "~/utils/api/v1/nurseService";
import timeBlockService from "~/utils/api/v1/timeblockService";
import { getAllCountryTimeblocks } from "~/utils/api/v2/nurseBlocktables";
import type { Locale } from "~/utils/interfaces/Locale";
import { GroupedTimeBlocks, TimeBlock } from "~/utils/interfaces/Timeblock";
import { groupTimeBlocks } from "~/utils/timeblocks/group";

const SAddCircleIcon = styled(AddCircleIcon)`
  margin-left: 15px;
  cursor: pointer;
`;

const TitleWrapper = styled.h1`
  margin: 20px 0px;
`;

interface ScheduleInterface {
  id: string;
}

const Schedule = (props: ScheduleInterface): JSX.Element => {
  const [country, setCountry] = useState<Locale | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [activeTimeblocks, setActiveTimeblocks] = useState<GroupedTimeBlocks>({});
  const [rawActiveTimeblocks, setRawActiveTimeblocks] = useState<TimeBlock[]>([]);
  const [allTimeblocks, setAllTimeblocks] = useState<TimeBlock[]>([]);
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [nurse, setNurse] = useState<any>({ names: "", last_names: "" });

  const fetchTimeblocks = useCallback(async () => {
    if (!country) return;

    setLoading(true);
    try {
      const [activeTimeblocksResponse, timeblocks] = await Promise.all([
        timeBlockService.fetchNurseTimeBlocks(props.id),
        getAllCountryTimeblocks(country),
      ]);
      const activeTimeblocks = activeTimeblocksResponse?.data?.data;
      if (activeTimeblocks.timeblocks) {
        setActiveTimeblocks(groupTimeBlocks(activeTimeblocks.timeblocks) as GroupedTimeBlocks);
        setRawActiveTimeblocks(activeTimeblocks.timeblocks);
      }
      if (timeblocks) {
        setAllTimeblocks(timeblocks);
      }
    } catch (err) {
      setError(err.response.data.error);
    }
    setLoading(false);
  }, [country]);

  const fetchNurse = useCallback(async () => {
    setLoading(true);
    try {
      const response = await nurseService.fetchNurseProfile(props.id);
      const nurse = response.data;
      if (nurse) {
        setNurse(nurse);
        setCountry(nurse.country);
      }
    } catch (err) {
      setError(err.response.data.error);
    }
    setLoading(false);
  }, []);

  const updateActiveTimeBlocks = (newTimeblocks: TimeBlock[]) => {
    const oldTimeblocks = [...rawActiveTimeblocks];
    const timeblocks = oldTimeblocks.concat(newTimeblocks);
    setRawActiveTimeblocks(timeblocks);
    setActiveTimeblocks(groupTimeBlocks(timeblocks) as GroupedTimeBlocks);
  };

  const deleteActiveTimeBlocks = (deletedTimeblocks: TimeBlock[]): void => {
    const oldTimeblocks = [...rawActiveTimeblocks];
    const newTimeblocks = differenceWith(oldTimeblocks, deletedTimeblocks, isEqual);
    setRawActiveTimeblocks(newTimeblocks);
    setActiveTimeblocks(groupTimeBlocks(newTimeblocks) as GroupedTimeBlocks);
  };

  useEffect(() => {
    fetchNurse();
  }, [fetchNurse]);

  useEffect(() => {
    fetchTimeblocks();
  }, [fetchTimeblocks]);

  return (
    <PrivateRoute>
      <h1>{`${nurse.names} ${nurse.last_names}`}</h1>
      <OutterContainer>
        <InnerContainer
          backgroundColor="white"
          padding="20px 40px"
        >
          <LoadingError
            loading={loading}
            error={error}
          />
          {!loading && !error && (
            <>
              <TitleWrapper>
                Horarios actuales
                {!showAdd && <SAddCircleIcon onClick={() => setShowAdd(true)} />}
              </TitleWrapper>
              {showAdd && allTimeblocks.length > 0 && (
                <AddTimeblock
                  onCancel={() => setShowAdd(false)}
                  timeblocks={allTimeblocks}
                  currentTimeblocks={rawActiveTimeblocks}
                  updateTimeblocks={updateActiveTimeBlocks}
                  id={props.id}
                  type="nurse"
                />
              )}
              <DisplayTimeBlock
                timeblocks={activeTimeblocks}
                deleteBlocks={deleteActiveTimeBlocks}
                isEditable
                id={props.id}
                type="nurse"
              />
            </>
          )}
        </InnerContainer>
      </OutterContainer>
    </PrivateRoute>
  );
};

export default Schedule;
